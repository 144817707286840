/* eslint-disable no-nested-ternary */
import React from 'react';
import { useSelector } from 'react-redux';
import Img from 'next/image';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Box from '@material-ui/core/Box';
import clsx from 'clsx';

import { SHOP_PAGE_PATHNAME } from '../../lib/CONST';
import Link from '../Link';
import SectionHeader from './SectionHeader';
import useStyles from './useStyles';

import logoZara from '../../../public/images/marcas/zara-logo.png';
import logoBenetton from '../../../public/images/marcas/benetton-logo.jpg';
import logoForever from '../../../public/images/marcas/forever21-logo.png';
import logoMango from '../../../public/images/marcas/mango-logo.png';
import logoMichelleBelau from '../../../public/images/marcas/michelle-belau-logo.jpg';
import logoHM from '../../../public/images/marcas/hm-logo.png';
import { createQueryString } from '../../lib/utils/search';

const searchState = {
  refinementList: {
    universo: 'mujer',
    marca: [
      'H&M',
      'Benetton',
      'Forever 21',
      'Michelle Belau',
      'Mango',
      'Zara',
    ],
  },
  message: 'Alarga el ciclo de vida de tus prendas favoritas',
};

const FILTER_URL = `${SHOP_PAGE_PATHNAME}/${createQueryString(searchState)}`;

const Marcas = () => {
  const styles = useStyles();
  const { sidebarVisible } = useSelector((state) => state.app);
  const isSmUp = useMediaQuery('(min-width:600px)');

  const logoDim = !isSmUp ? 100 : 150;

  return (
    <Box className={`${clsx(styles.container, styles.marcasContainer)} px-2 md:px-4 lg:px-8`}>
      <SectionHeader>Alarga el ciclo de vida de tus prendas favoritas</SectionHeader>
      <Link href={FILTER_URL} passHref>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <a
          className={`${clsx([
            styles.logoContainer,
            sidebarVisible ? styles.noScrollBar : styles.scrollBar,
          ])} cursor-pointer`}
        >
          <span className="rounded-full border border-black bg-white">
            <Img
              src={logoHM}
              width={logoDim}
              height={logoDim}
              alt="Logo de H&M"
              className="rounded-full"
              layout="fixed"
            />
          </span>
          <span className="rounded-full border border-black bg-white">
            <Img
              src={logoBenetton}
              width={logoDim}
              height={logoDim}
              alt="Logo de Benetton"
              className="rounded-full"
              layout="fixed"
            />
          </span>
          <span className="rounded-full border border-black bg-white">
            <Img
              src={logoForever}
              width={logoDim}
              height={logoDim}
              alt="Logo de Forever 21"
              className="rounded-full"
              layout="fixed"
            />
          </span>
          <span className="rounded-full border border-black bg-white">
            <Img
              src={logoMango}
              width={logoDim}
              height={logoDim}
              alt="Logo de Mango"
              className="rounded-full"
              layout="fixed"
            />
          </span>
          <span className="rounded-full border border-black bg-white">
            <Img
              src={logoMichelleBelau}
              width={logoDim}
              height={logoDim}
              alt="Logo de Michelle Belau"
              className="rounded-full"
              layout="fixed"
            />
          </span>
          <span className="rounded-full border border-black bg-white">
            <Img
              src={logoZara}
              width={logoDim}
              height={logoDim}
              alt="Logo de Zara"
              className="rounded-full"
              layout="fixed"
            />
          </span>
        </a>
      </Link>
    </Box>
  );
};

export default Marcas;
